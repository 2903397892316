import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';
import { API_URLS } from '../configuration/api.constants';

export const getRoles = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.ROLES_BY_APP(process.env.REACT_APP_ORIGIN_APP_ID),
    api: process.env.REACT_APP_AUTH_API,
    originApp: true
  }
  const roles = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response.roles);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}))
    })
  })
  return roles;
}