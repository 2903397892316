import "../assets/scss/login.scss";
import Text from "../components/ingka/Text";
import { useTranslation } from "react-i18next";
import Button from "../components/ingka/Button";
import { useDispatch, useSelector } from "react-redux";

import { changeLang, doCheckUser, doLogin, doToken } from "../store/globalSlice";
import Load from '../components/ingka/Load';
import { useEffect, useState } from "react";
import { authSSO, checkUser, getMaintenance } from "../services/loginSevices";

export default function Login() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [year, setYear] = useState("20**")
  const token = useSelector((state) => state.global.token)
  const [maintenance, setMaintenance] = useState(false)

  useEffect(()=>{
    calculateYear()
    lookAtMaintenance()
  },[])

  const calculateYear = () => {
    const date = new Date()
    setYear(date.getFullYear())
  }

  const lookAtMaintenance = () => {
    getMaintenance().then( inMaintenance => {
      if(inMaintenance){
        setMaintenance(true)
      } else {
        goToGetCredentials()
      }
    }).finally(setLoading(false))
  }

  const goToSSO = () => {
    window.location.href = "https://login.microsoftonline.com/720b637a-655a-40cf-816a-f22f40755c2c/oauth2/v2.0/authorize?client_id="+
    process.env.REACT_APP_MICROSOFT_CLIENT_ID+"&redirect_uri="+
    process.env.REACT_APP_MICROSOFT_REDIRECT_URI+"&scope="+
    process.env.REACT_APP_MICROSOFT_SCOPE
    +"&response_type=code&response_mode=query"
  }

  const goToGetCredentials = ()=>{
    const values = window.location.search
    //There is no token but there is a code in slug
    if((values && token === "undefined") || (values && token === null) ){
      const urlParams = new URLSearchParams(values)
      const code = urlParams.get('code');
      if(code){
        setLoading(true)
        authSSO({
          code: code, 
          redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI,
          originApp: process.env.REACT_APP_ORIGIN_APP,
          restrictionOffice: process.env.REACT_APP_RESTRICTION_OFFICE
        }).then((response) => {
          dispatch(doLogin({
            rol: {id: response.rolId, name: response.rolName},
            user:{
              nickName: response.mailNickName,
              name: response.displayName,
              email: response.mail,
            }
          }))
          dispatch(doToken(response.accessToken))
          dispatch(changeLang(response.preferredLanguage))
          goToCheckUser(response)
        })
      }
    } else {
      localStorage.clear()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const goToCheckUser = (data) => {
    checkUser(data).then(() => {
      dispatch(doCheckUser())
    })
  }

  return (
    <div className="login">
      <div className="container">
        <div className="brand">
          <Text
            tagName="h1"  
            className="title">
              <span>{t('login.title')}</span>
          </Text>
          <Text
            tagName="h2" 
            headingSize="s"
            className="subtitle">
              {t("login.subtitle")}
          </Text>
        </div>
      </div>
      <div className="form">
          <div className="container">
            {!loading &&
            <div className="form-container">
              {!maintenance &&
                <Button
                  data-id="login-btn"
                  type="emphasised"
                  text={t("login.buttonContinue")}
                  onClick={goToSSO}
                ></Button>
              }
              {maintenance &&
                <div>
                  <Text tagName="h3" headingSize="l">{t('login.appInMaintenanceTitle')}</Text>
                  <Text tagName="p" bodySize="xl">{t('login.appInMaintenanceMsg')}</Text>
                </div>
              }
            </div>
            }
            {loading &&
            <div className="mt-3">
              <Load/>
            </div>
            }
            
          </div>
        </div>
        <div className="copy">
        <Text
            tagName="span" 
            bodySize="s">
              @Digital Spain 2021-{year}
          </Text>
          
        </div>
    </div>
  )
}
