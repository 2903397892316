import { useTranslation } from 'react-i18next';
import { Fragment, useState, useEffect } from 'react';
import { getUsers, updateUsers } from './../services/usersService';
import { getPaymentTypes } from './../services/paymentTypeService';
import { getRoles } from './../services/roleService';
import Text from '../components/ingka/Text';
import Load from './../components/ingka/Load'
import Table from '../components/global/Table';
import Button from '../components/ingka/Button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import CreateEditUser from '../components/global/CreateEditUser';
import { ROLE } from '../configuration/globalConstants';
import SSRIcon from '@ingka/ssr-icon'
import eye from '@ingka/ssr-icon/paths/visibility-show'
import Switch from '../components/ingka/Switch';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../components/global/ConfirmationModal';

export default function Users() {
  const [t] = useTranslation('global');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [filteredAppUsers, setFilteredAppUsers] = useState(null)
  const [userSelect, setUserSelect] = useState(null);
  const [modalUser, setModalUser] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(null);
  const [rolesOptions, setRolesOptions] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const rol = useSelector((state) => state.global.rol.id)
  const cellStatus = ({ row }) => {
    return <Switch
      props={{
        className: "zoom08",
        value: row.original.active,
        checked: row.original.active,
        onChange: e => handleActive(e.target.checked, row.original),
        disabled: rol !== ROLE.SUPERADMIN
      }}
    />
  }
  const columns = [
    { accessorKey: "nickName", header: t('users.user'), sortable: true },
    { accessorKey: "name", header: t('global.name'), sortable: true },
    { accessorKey: "roleName", header: t('users.profile'), sortable: true },
    {
      accessorKey: "minLimit", header: t('users.limit'), Cell: ({ row }) => (
        <span>{`De ${row.original.minLimit} a ${row.original.maxLimit}`}</span>
      )
    },
    {
      accessorKey: "paymentType", header: t('users.paymentMethod'), sortable: true, Cell: ({ row }) => (
        <span>{row.original.paymentType ? row.original.paymentType.name : t('users.allPayments')}</span>
      )
    },
    { accessorKey: "active", header: t('global.active'), Cell: cellStatus }
  ];
  const [buttonsView, setButtonsView] = useState([
    {
      text: t('users.viewAll'),
      mode: "all"
    }
  ])
  const [viewSelected, setViewSelected] = useState("all")

  useEffect(() => {
    getAllUsers();
    getRolesOptions()
  }, [])

  const getAllUsers = () => {
    getUsers().then(users => {
      setUsers(users);
      setFilteredAppUsers(users)
    }).finally(() => setLoading(false))
  }

  const goEdit = (user) => {
    if (paymentTypeOptions === null) getPaymentTypeOptions();

    setUserSelect(user);
    setModalUser(!modalUser);
  }

  const getPaymentTypeOptions = () => {
    getPaymentTypes().then(options => {
      const formatOptions = options.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      formatOptions.unshift({ label: t('users.allPayments'), value: null })
      setPaymentTypeOptions(formatOptions);
    }).finally(() => setLoading(false))
  }

  const getRolesOptions = () => {
    getRoles().then(options => {
      const formatOptions = options.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setRolesOptions(formatOptions);
      setButtonsView([...buttonsView, ...options.map(rol => ({ text: rol.name, mode: rol.name }))])
    }).finally(() => setLoading(false))
  }

  const updateUser = (data) => {
    console.log(data)
    updateUsers(data).then(() => {
      getAllUsers()
    })
  }

  const handleActive = (checked, user) => {
    setConfirmation({
      title: t('users.changeActive'),
      msg: t('users.changeActiveMsg'),
      button: t('global.save'),
      action: () => updateUser({
        userId: user.id,
        userUpdate: { ...user, active: checked },
        successMsg: t("users.successUpdateActive")
      }
      )
    })
  }

  const updateView = (mode) => {
    setViewSelected(mode)
    setFilteredAppUsers(
      users.filter(user => {
        if (mode === "all") return user
        return user.roleName === mode
      })
    )
  }

  return (
    <Fragment>
      <Text tagName="h2" headingSize="m">{t('users.title')}</Text>
      {loading &&
        <Load />
      }
      {!loading && columns && filteredAppUsers &&
        <>
          <article style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}>
            <SSRIcon paths={eye} />
            <Text >{filteredAppUsers.length}</Text>
            {buttonsView.map(btn =>
              <Button
                key={btn.text}
                type="secondary"
                small
                className="ml-1"
                text={btn.text}
                onClick={() => updateView(btn.mode)}
                disabled={viewSelected === btn.mode}
              />
            )}
          </article>
          <Table
            columns={columns}
            data={filteredAppUsers}
            localization={{
              noRecordsToDisplay: t('users.noUsers')
            }}
            enableSorting={true}
            enablePagination={false}
            enableRowActions={true}
            renderRowActions={({ row }) => (
              <section className="justify-content-end">
                <Button
                  className="table-icon-action"
                  onClick={() => goEdit(row.original)}
                  type="tertiary"
                  iconOnly
                  ssrIcon={pencil}
                  small
                  qa="btn-edit-user"
                  disabled={row.original.roleId === ROLE.VISITOR || row.original.roleId === ROLE.SUPERADMINVISITOR}
                />
              </section>
            )
            }
          />
          <ConfirmationModal confirmation={confirmation} />
        </>
      }
      {!loading && modalUser &&
        <CreateEditUser
          visible={modalUser}
          title={t('users.edit')}
          paymentTypeOptions={paymentTypeOptions}
          rolesOptions={rolesOptions}
          userSelect={userSelect}
          onUpdateUser={(user) => updateUser({
            userId: userSelect.id,
            userUpdate: { ...user, active: true },
            successMsg: t("users.successEdit")
          })}
          onClose={() => {
            setModalUser(!modalUser);
            setUserSelect(null);
          }}
        />
      }
    </Fragment>
  )
}
