export const STATE = {
  AVAILABLE: 3, 
  CLOSE: 6,
  PENDING: 7,
  RESERVED: 5,
  INCIDENCE: 11,
  ASSIGNED: 4,
  N546: 8,
  BUSINESS: 9,
  TO_REVIEW: 2
}
