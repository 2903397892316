import { useTranslation } from "react-i18next";
import Select from "./Select";
import { useState } from "react";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import Button from "../ingka/Button";
import { billsActions } from "../../services/billServices";
import { STATE } from "../../utils/billStates";
import { getUsers } from "../../services/usersService";
import style from "./billActions.module.scss"
import Text from "../ingka/Text";
import { useDispatch } from "react-redux";
import { createMsgToast } from "../../store/globalSlice";
import { exportBills } from "../../services/exportServices";

export default function BillActions({
  billsSelected,
  bills,
  isMulti=false,
  reloadPage
}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const EXPORT_ACTION = "export"
  const REOPEN_ACTION = "reopen"
  const REASSIGN_ACTION = "reassign"
  const ASSIGN_ACTION = "assign"

  const actionsOptions = [
    {label:t('bills.assign') ,value: ASSIGN_ACTION},
    {label:t('bills.reopen') ,value: REOPEN_ACTION},
    {label:t('bills.reassign') ,value: REASSIGN_ACTION},
    {label:t('global.export') ,value: EXPORT_ACTION}
  ]

  const [action, setAction] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState(null)
  const [userOptions, setUserOptions] = useState(null)
  const [actionError, setActionError] = useState(false)

  const isIncompatible = (bill, action)=> {
    return bill.state.id === STATE.PENDING || 
      (action === REASSIGN_ACTION && bill.state.id !== STATE.RESERVED && bill.state.id !== STATE.ASSIGNED && bill.state.id !== STATE.BUSINESS && bill.state.id !== STATE.TO_REVIEW) ||
      (action === REOPEN_ACTION && (bill.state.id !== STATE.CLOSE && bill.state.id !== STATE.INCIDENCE && bill.state.id !== STATE.BUSINESS && bill.state.id !== STATE.TO_REVIEW)) ||
      (action === ASSIGN_ACTION && bill.state.id !== STATE.AVAILABLE)
  }

  const incompatibleLines = (action) => {
    const billsIndexed = bills.reduce((acc, el) => {
      acc[el.id] = el
      return acc
    },{})
    return billsSelected.some( billId => { 
      const bill = billsIndexed[billId] 
      return isIncompatible(bill, action)
    })
  }

  const showWarning = (action) => {
    setAction(action)
    setModalOpen(true)
    if(action.value !== EXPORT_ACTION){
      goToGetUsers()
      if(incompatibleLines(action.value)) setActionError(true)
    }
  }

  const goToGetUsers = () =>{
    getUsers().then(users => {
      const userOpt = users.map( user => ({value: user.nickName, label: user.name}))
      setUserOptions(userOpt);
    })
  }

  const doAction = () =>{
    if(action.value !== EXPORT_ACTION){
      billsActions({
        billsId: billsSelected.toString(), 
        userId: user.value,
        action: action.value
      })
      .then(response => {
        const msg = createMsg(response)
        dispatch(createMsgToast({
          body: msg,
          variant: response.nonUpdateInvoices.length === 0 ? "positive" : "cautionary"
        }))
        setModalOpen(false)
        reloadPage(user.value)
      })
    }else{
      exportBillsSelected()
    }
  }

  const createMsg = (response) => {
    let msg = ""
    if(response.updateInvoices.length > 0){
      msg = billsSelected.length > 1 ? (response.updateInvoices.length + " " + t(`bills.${action.value}Msg`)): t(`bill.${action.value}Msg`)
    }
    if(response.nonUpdateInvoices.length > 0){
      if(response.updateInvoices.length > 0) msg += " "
      msg += billsSelected.length > 1 ? (response.nonUpdateInvoices.length + " " + t(`bills.nonUpdated`)): t(`bill.nonUpdated`)
    }
    return msg       
  }

  const exportBillsSelected = () => {
    closeModal()
    exportBills({
      invoicesIds: billsSelected,
      requestSendMsg: t('bills.exportBills'),
      downloadSuccess: t('bills.downloadSuccess')
    })
  }

  const closeModal = () => {
    setModalOpen(false)
    setAction(null)
    setActionError(false)
  }

  return (
    <section qa="actionsComponent"> 
      <Select
        formField={{className:"col-12"}}
        label={isMulti ? t('bills.actionForLines') : t('global.action')}
        onChange={val => showWarning(val)}
        value={action}
        options={actionsOptions}
        isDisabled={isMulti ? billsSelected.length === 0 : false}
      />
      {action &&
        <Modal
          visible={modalOpen}
          escapable={false}
          handleCloseBtn={() => closeModal()}
          qa="actionModal"
        >
          <Prompt
            className={style.modal}
            title={`${action.label} ${isMulti ? t('global.lines').toLowerCase(): ''}`}
            header={<ModalHeader ariaCloseTxt={t('global.close')} />}
            footer={
              <ModalFooter>
                <Button 
                  text={t('global.cancel')} 
                  type="secondary" 
                  onClick={()=> closeModal()}
                  className={actionError ? "w-100" : ""}
                />
                {!actionError &&
                  <Button 
                  text={action.label} 
                  type="emphasised" 
                  onClick={() => doAction()} 
                  disabled={user === null && action.value !== EXPORT_ACTION}
                  qa="submit"
                  />
                }
              </ModalFooter>
            }
          >
            {!actionError && action.value !== EXPORT_ACTION &&
              <div className={style.box}>
                <Select
                  formField={{className:''}}
                  label={t('bills.userAction')}
                  onChange={val => setUser(val)}
                  value={user}
                  isMulti={false}
                  isSearchable={true}
                  id="user"
                  name="user"
                  options={userOptions}
                  defaultValue={null}
                  isLoading={userOptions === null}
                />
                
                {user &&
                  <>
                    <Text tagName="p" bodySize="m">{t(`bills.msgWarning-${action.value}`)}</Text>
                    <Text tagName="p" bodySize="m">{t('bills.msgWarningAction')}</Text>
                  </>
                }
              </div>
            }
            {actionError &&
              <Text tagName="p" bodySize="m">{t(`bills.msgActionError-${action.value}`)}</Text>
            }
            {action.value === EXPORT_ACTION &&
              <Text tagName="p" bodySize="m">{t('bills.msgWarningExport')}</Text>
            }
          </Prompt>
        </Modal>
      }
    </section>
  )
}
