/*LOGIN*/
export const CODE_MULTI_FACTOR_AUTH = "AADSTS50076"
export const CODE_AUTH_EXPIRED = "AADSTS70008"

/*ROLES*/ 
export const ROLE = {
  SUPERADMIN: 1,
  ADMIN: 2,
  BILLER: 4,
  VISITOR: 9,
  SUPERADMINVISITOR: 10
}