import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import Button from '../ingka/Button';
import "@ingka/modal/style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function ConfirmationModal({confirmation}) {
  const[modalOpen, setModalOpen] = useState(false)
  const [t] = useTranslation("global")

  useEffect(() => {
    if(confirmation){
      setModalOpen(true)
    }else{
      setModalOpen(false)
    }
  },[confirmation])

  const goAction = () => {
    confirmation.action()
    setModalOpen(false)
  }

  return (
    <Modal
      visible={modalOpen}
      focusLockProps={{
        disabled: false
      }}
      handleCloseBtn={()=> setModalOpen(!modalOpen)}
      data-id="confirmationModal"
    >
      <Prompt
        footer={
          <ModalFooter>
            <Button 
            text={t('global.cancel')} 
            type="secondary" 
            data-id="cancelAction"
            />
            <Button 
            text={confirmation?.button} 
            type="emphasised"
            data-id="confirmAction"
            onClick={() => goAction()}
            />
          </ModalFooter>
        }
        header={<ModalHeader />}
        title={confirmation?.title}
      >
        <p>
          {confirmation?.msg}
        </p>
      </Prompt>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  confirmation: PropTypes.object
}